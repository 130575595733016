<template>
  <div class="container">
    <div class="content">
      <div class="content-top">
        <div style="display: flex;flex-direction:column;align-items: flex-start;">
          <div class="first-line">
            <div class="date-box" style="padding-left: 12px;">
              <div class="label" style="width:90px;text-align: right;">请选择大类</div>
              <el-select filterable @change="changeBigType" v-model="firstName" placeholder="请选择" style="width: 132px;">
                <el-option
                  v-for="item in bigTypes"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
            </div>
            <div class="date-box" style="padding-left: 12px;">
              <div class="label" style="width:90px;text-align: right;">请选择小类</div>
              <el-select filterable @change="changeSmallType" v-model="secondName" placeholder="请选择" style="width: 132px;">
                <el-option
                  v-for="item in smallTypes"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
            </div>
            <div class="date-box" style="padding-left: 50px;">
              <el-input
                style="width: 180px;margin-right: 10px;"
                placeholder="输入菜名或关联名"
                v-model="searchName"
                clearable>
              </el-input>
            </div>
            <el-button type="primary" icon="el-icon-search" @click="search">查询</el-button>
          </div>
          <div class="first-line">
            <el-button type="primary" icon="el-icon-folder-add" @click="createFoodName">添加菜名</el-button>
            <div class="date-box" style="padding-left: 12px;" v-if="createFoodNameFlag">
              <div class="label" style="width: 200px;">新增菜名称</div>
              <el-input
                placeholder="请输入内容"
                v-model="newFoodName"
                clearable>
              </el-input>
              <el-button type="success" style="margin-left: 10px;" :disabled="!newFoodName" @click="addOrEditFoodName('add')"" >确定</el-button>
              <el-button type="danger" style="margin-left: 10px;" @click="cancelCreateFoodName" >取消</el-button>
            </div>
          </div>
        </div>
      </div>
      <div class="content-bottom">
        <el-table
          :data="foodNames"
          stripe
          style="width:100%;flex:1;overflow:auto;"
          v-loading="listLoading"
        >
          <el-table-column
            label="时间"
            width="180">
            <template slot-scope="scope">
              {{scope.row.createTime | rTime}}
            </template>
          </el-table-column>
          <el-table-column
            prop="orderNumber"
            label="菜名称"
            width="200">
            <template slot-scope="scope">
              {{scope.row.name}}
            </template>
          </el-table-column>
          <el-table-column
            label="重命名"
            prop="orderStatusName"
            width="250">
            <template slot-scope="scope">
              <div style="display: flex;align-items: center;">
                <el-input
                  stlye="width: 100px;"
                  placeholder="请输入内容"
                  v-model="scope.row.newName"
                  clearable>
                </el-input>
                <span @click="reName('confirm',scope)" class="el-icon-circle-check" style="color: #67c23a;font-size: 24px;margin: 0 10px;cursor: pointer;" ></span>
                <span @click="reName('cancel',scope)" class="el-icon-circle-close" style="color: #f56c6c;font-size: 24px;cursor: pointer;"></span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="关联名称"
            width="250">
            <template slot-scope="scope">
              <el-tag
                style="margin-right: 5px;margin-bottom: 5px;"
                v-for="tag in scope.row.remarkList"
                @close="handleClose(tag)"
                :key="tag.name"
                closable>
                {{tag.name}}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column
            label="添加关联名称"
            width="250">
            <template slot-scope="scope">
              <div style="display: flex;align-items: center;">
                <el-input
                  stlye="width: 100px;"
                  placeholder="请输入内容"
                  v-model="scope.row.associationName"
                  clearable>
                </el-input>
                <span @click="addAssociationName('confirm',scope)" class="el-icon-circle-check" style="color: #67c23a;font-size: 24px;margin: 0 10px;cursor: pointer;" ></span>
                <span @click="addAssociationName('cancel',scope)" class="el-icon-circle-close" style="color: #f56c6c;font-size: 24px;cursor: pointer;"></span>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="pageSize"
        :page-sizes="[5, 10, 20, 40]"
        layout="total, sizes, prev, pager, next, jumper"
        background
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        value: '',
        accountName: '',  //旺旺名
        orderNumber: '',  //订单号
        total: 0,
        pageSize: 10,
        currentPage:1,
        listLoading: false,
        type: '',
        id: '',
        checkAll: false,
        checkedCities: [],
        idList: [],
        isIndeterminate: false,
        queryId: '',  //上个界面
        findPictureData: {},
        bigTypes: [],  //大类
        smallTypes: [],
        foodNames: [],
        firstName:'',
        secondName:'',
        timer: null,
        loading: false,
        createFoodNameFlag: false,
        newFoodName: '',
        searchName: '',
        lastName: ''
      };
    },
    props:{
      firstIdProp: {
        default: 1
      },
      secondIdProp: {
        default: 1
      }
    },
    created () {
      console.log('this.firstIdProp',this.firstIdProp)
      this.queryBigTypes(); //查询大类
    },
    methods: {
      //添加大类
      createFoodName(){
        this.createFoodNameFlag = true;
      },
      cancelCreateFoodName(){
        this.createFoodNameFlag = false;
        this.newFoodName = '';
      },
      reName(type,scope){
        if(scope.row.name === scope.row.newName) return
        console.log('更改了')
        if(type === 'confirm'){
          this.addOrEditFoodName('edit',scope);
        }else{
          this.foodNames[scope.$index].newName = this.foodNames[scope.$index].name;
        }
      },
      addAssociationName(type,scope){
        if(type === 'confirm'){
          const data = {
            name: scope.row.associationName,
            lastId: scope.row.id
          }
          this.$http.post('/menuName/saveLastRemark', data, { headers: {
              token: window.sessionStorage.manageToken
            }})
            .then(res => {
              console.log('res====',res)
              console.log('resdata====',res.data)
              //  console.log("输出response.data", res.data);
              if (res.data.code === 'ok') {
                console.log('resdatadata====',res.data.data);
                this.$message({
                  type: 'success',
                  message: '新增成功'
                });
                this.changeSmallType();
              } else {
                this.$message.error(res.data.data)
              }
            });
        }else{
          this.foodNames[scope.$index].associationName = '';
        }
      },
      //新增或者重命名
      addOrEditFoodName(type,scope){
        let data = {};
        let url = '';
        if(type==="add"){
          data = {
            name: this.newFoodName,
            secondId: this.secondName
          }
          url = '/menuName/saveLastName'
        }else{
          data = {
            name: scope.row.newName,
            id: scope.row.id
          }
          url = '/menuName/updateLastName'
        }
        console.log(data)
        this.$http.post(url, data, { headers: {
            token: window.sessionStorage.manageToken
          }})
          .then(res => {
            console.log('res====',res)
            console.log('resdata====',res.data)
            //  console.log("输出response.data", res.data);
            if (res.data.code === 'ok') {
              console.log('resdatadata====',res.data.data);
              const data = res.data.data || {}
              if(type==="add"){
                this.createFoodNameFlag = false;
                this.newFoodName = '';
                this.$message({
                  type: 'success',
                  message: '新增成功'
                });
              }else{
                this.$message({
                  type: 'success',
                  message: '更改成功'
                });
              }
              this.changeSmallType();
            } else {
              this.$message.error(res.data.data)
            }
          });
      },
      queryBigTypes(){
        this.$http.post(`/menuName/findFirstName`, {}, { headers: {
            token: window.sessionStorage.manageToken
          }})
          .then(res => {
            console.log('res====queryOptions',res)
            console.log('resdata====',res.data)
            if (res.data.code === 'ok') {
              console.log('resdatadata====',res.data.data)
              this.bigTypes = res.data.data;
              this.firstName= this.firstIdProp;
              this.changeBigType();
              // this.bigTypes.forEach((item,index)=>{
              //   this.$set(this.bigTypes[index],'newName',item.name)
              //  // item.newName = item.name
              // })
            } else {
              this.$message.error(res.data.data)
            }
          });
      },
      changeBigType(){
        console.log('changeBigType=======')
        const data = {
          firstId: this.firstName
        }
        this.$http.post("/menuName/findSecondName", data, { headers: {
            token: window.sessionStorage.manageToken
          }})
          .then(res => {
            console.log('res====',res)
            console.log('resdata====',res.data)
            if (res.data.code === 'ok') {
              console.log('resdatadata====',res.data.data)
              this.smallTypes = res.data.data;
              // this.smallTypes.forEach((item,index)=>{
              //   this.$set(this.smallTypes[index],'newName',item.name)
              //   // item.newName = item.name
              // })
              if(this.secondName){
                this.secondName = this.smallTypes[0].id;
              }else{
                this.secondName= this.secondIdProp;
              }
              this.changeSmallType();
            } else {
              this.$message.error(res.data.data)
            }
          });
      },
      changeSmallType(){
        console.log('changeSmallType=======')
        const data = {
          secondId: this.secondName,
          lastName: this.lastName
        }
        this.$http.post(`/menuName/findLastName/page/${this.currentPage}/${this.pageSize}`, data, { headers: {
            token: window.sessionStorage.manageToken
          }})
          .then(res => {
            console.log('findLastName====',res)
            console.log('resdata====',res.data)
            if (res.data.code === 'ok') {
              console.log('resdatadata====',res.data.data)
              this.foodNames = res.data.data.list;
              this.total = res.data.data.total;
              this.foodNames.forEach((item,index)=>{
                this.$set(this.foodNames[index],'newName',item.name)
                this.$set(this.foodNames[index],'associationName','')
                // item.newName = item.name
              })
            } else {
              this.$message.error(res.data.data)
            }
          });
      },
      search(){
        this.currentPage = 1;
        this.lastName = this.searchName;
        this.changeSmallType();
      },
      handleSizeChange: function (size) {
        this.pageSize = size;
        console.log(this.pageSize);  //每页下拉显示数据
        this.changeSmallType();
      },
      handleCurrentChange: function(currentPage){
        this.currentPage = currentPage;
        console.log(this.currentPage);  //点击第几页
        this.changeSmallType();
      },
      handleClose(tag){
        console.log(tag)
        this.$http.post("/menuName/deleteLastRemark", {id: tag.id}, { headers: {
            token: window.sessionStorage.manageToken
          }})
          .then(res => {
            console.log('res====',res)
            console.log('resdata====',res.data)
            //  console.log("输出response.data", res.data);
            if (res.data.code === 'ok') {
              console.log('resdatadata====',res.data.data)
              this.$message({
                type: 'success',
                message: '删除成功'
              });
              this.changeSmallType()
            } else {
              this.$message.error(res.data.data)
            }
          });
      }

    },
    watch: {

    },
    filters: {
      rTime(data){
        if(!data) return "---"
        var dt = new Date(data);
        var y = dt.getFullYear();
        var m = (dt.getMonth()+1).toString().padStart(2,"0");
        var d = dt.getDate().toString().padStart(2,"0");
        var h = dt.getHours().toString().padStart(2,"0");
        var mm = dt.getMinutes().toString().padStart(2,"0");
        var s = dt.getSeconds().toString().padStart(2,"0");
        return `${y}-${m}-${d} ${h}:${mm}:${s}`;
      },
    },
    computed: {

    }
  };
</script>
<style lang="less" scoped>
  .container {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  .content {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-top: 10px;
    /*margin-bottom: 20px;*/
    overflow: auto;
    .content-top {
      display: flex;
      margin-top: 10px;
      justify-content: flex-start;
      flex-direction: column;
    }
    .content-bottom {
      flex: 1;
      display: flex;
      flex-direction: column;
      overflow: auto;
    }
  }
  .first-line {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }
  .label {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /*text-align: right;*/
    font-size: 16px;
    color: #000000;
    padding-right: 10px;
  }
  .date-box {
    display: flex;
    align-items: center;
    padding-left: 12px;
  }
  .third-line {
    margin-left: 20px;
    margin-top: 10px;
  }
  .content-box {
    display: flex;
    flex-direction: column;
  }
  .food-content {
    /*flex:1;*/
    display: flex;
    flex-direction: column;
    /*flex-wrap: wrap;*/
    /*justify-content: flex-start;*/
    margin: 10px;
    margin-bottom: 0;
    font-size: 12px;
    overflow: auto;
    .secondName {
      background-color: rgb(111,161,212);
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      height: 38px;
      font-size: 16px;
      position: relative;
      .button-box {
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  .food-box {
    display: flex;
    flex-direction: column;
    width: 230px;
    margin: 0 40px 10px 0;
    position: relative;
    /deep/ .el-checkbox {
      position: absolute;
      top: 5px;
      left: 5px;
    }
    /deep/ .el-checkbox__label {
      display: none;
    }
  }
  .food-image {
    width: 100%;
    height: 200px;
    object-fit: contain;;//转化不同类型
    /*padding: 3px;*/
    /*background-color: #999;*/
    /*box-sizing: border-box;*/
  }
  .image-size {
    display: flex;
    background-color: #999;
    padding: 3px 0 4px;
    align-items: center;
    justify-content: center;
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 3px;
      .image-label {
        display: flex;
        background-color: #ccc;
        width: 40px;
        padding: 3px 10px;
        border-radius: 20px;
        background-color: rgb(225,229,238);
        &:first-child {
          margin-right: 10px;
        }
      }
      .image-value {
        display: flex;
        flex: 1;
        background-color: #fff;
        padding: 3px;
        border-radius: 0 10px 10px 0;
      }
    }
  }
  .image-desc {
    display: flex;
    flex-direction: column;
    .big-type {
      font-size: 14px;
      text-align: left;
      padding: 3px 0 3px 10px;
      border: 3px solid #ccc;
    }
    .image-info {
      font-size: 14px;
      border: 3px solid #ccc;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #ccc;
      .info-item{
        flex: 1;
        /*display: flex;*/
        background-color: #fff;
        margin-right: 5px;
      }
    }
  }
  /deep/ .cell {
    text-align: center;
  }
  /deep/ .el-tooltip.copy  {
    margin-right: 0!important;
    width: auto!important;
  }
  .dialog-image-box {
    display: flex;
    flex-wrap: wrap;
  }
  .dialog-image-item {
    width: 150px;
    height: 150px;
    padding: 5px;
    position: relative;
    /deep/ img {
      width: 100%;
      height: 100%;
      object-fit: contain;;//转化不同类型
    }
    .image-radio {
      position: absolute;
      top: 10px;
      left: 10px;
      /deep/ .el-radio__label {
        display: none;
      }
    }
  }
  /deep/ .el-pagination {
    position: fixed;
    bottom: 38px;
    left: 28%;
    background-color: rgba(255,255,255,.9);
    z-index: 1;
  }
</style>

