<template>
  <div class="container" v-loading="loading"
       element-loading-text="正在导出中"
       element-loading-spinner="el-icon-loading"
       element-loading-background="rgba(0, 0, 0, 0.8)">
    <div class="content">
      <div class="content-top">
        <div style="display: flex;justify-content: flex-start;">
          <div class="first-line">
            <el-button type="primary" icon="el-icon-folder-add" @click="createBigType">添加大类</el-button>
            <div class="date-box" style="padding-left: 12px;" v-if="createBigTypeFlag">
              <div class="label" style="width: 200px;">新增大类名称</div>
              <el-input
                placeholder="请输入内容"
                v-model="newBigType"
                clearable>
              </el-input>
              <el-button type="success" style="margin-left: 10px;" :disabled="!newBigType" @click="addOrEditBigType('add')"" >确定</el-button>
              <el-button type="danger" style="margin-left: 10px;" @click="cancelCreateBig" >取消</el-button>
            </div>
          </div>
        </div>
      </div>
      <div class="content-bottom">
        <el-table
          :data="bigTypes"
          stripe
          style="width:100%;flex:1;overflow:auto;"
          v-loading="listLoading"
        >
          <el-table-column
            label="时间"
            width="180">
            <template slot-scope="scope">
              {{scope.row.createTime | rTime}}
            </template>
          </el-table-column>
          <el-table-column
            prop="orderNumber"
            label="大类名称"
            width="200">
            <template slot-scope="scope">
              {{scope.row.name}}
            </template>
          </el-table-column>
          <el-table-column
            label="重命名"
            prop="orderStatusName"
            width="280">
            <template slot-scope="scope">
              <div style="display: flex;align-items: center;">
                <el-input
                  stlye="width: 100px;"
                  placeholder="请输入内容"
                  v-model="scope.row.newName"
                  clearable>
                </el-input>
                <span @click="reName('confirm',scope)" class="el-icon-circle-check" style="color: #67c23a;font-size: 24px;margin: 0 10px;cursor: pointer;" ></span>
                <span @click="reName('cancel',scope)" class="el-icon-circle-close" style="color: #f56c6c;font-size: 24px;cursor: pointer;"></span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="编辑小类"
            prop="orderStatusName"
            width="180">
            <template slot-scope="scope">
              <div @click="changeActiveName(scope.row.id)">
                编辑小类
                <span class="el-icon-right" style="color: #409EFF;"></span>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <el-dialog title="在线选图" :close-on-click-modal="false" :visible.sync="dialogTableVisible" center :append-to-body='true' :lock-scroll="false" width="80%">
      <div class="">
        <div class="first-line">
          <div class="date-box" style="padding-left: 12px;">
            <div class="label" style="width: 50px;text-align: right;">大类</div>
            <el-select filterable @change="changeBigType" v-model="firstName" placeholder="请选择" style="width: 132px;">
              <el-option
                v-for="item in bigTypes"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div class="date-box" style="padding-left: 12px;">
            <div class="label" style="width: 50px;text-align: right;">小类</div>
            <el-select filterable @change="changeSmallType" v-model="secondName" placeholder="请选择" style="width: 132px;">
              <el-option
                v-for="item in smallTypes"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div class="date-box" style="padding-left: 12px;">
            <div class="label" style="width: 50px;text-align: right;">名称</div>
            <el-select filterable v-model="lastName" placeholder="请选择" style="width: 132px;">
              <el-option
                v-for="item in foodNames"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div class="date-box" style="padding-left: 12px;">
            <el-button type="primary" icon="el-icon-search" @click="search">查询</el-button>
          </div>
        </div>
        <div class="dialog-image-box" style="width: 100%;display: flex;justify-content: center;">
          <div class="dialog-image-item" v-for="(item,index) in imgDataList" :key="index">
            <el-image style="width: 150px;height: 150px;"
              :src="baseURL + '/images'+item.imgSmallUrl"
              :preview-src-list="imageSrcList"
            >
            </el-image>
            <el-radio class="image-radio" v-model="imageRadioId" :label="index"></el-radio>
          </div>
          <div style="text-align: center;font-size: 14px;color: #909399;margin-top: 10px;" v-if="!(imgDataList && imgDataList.length > 0)">暂无数据</div>
        </div>
        <div style="display: flex;justify-content: center;margin-bottom: 10px;">
          <el-button :disabled="!imageRadioId && imageRadioId !== 0" width="80px" type="primary" size="mid" style="margin-top: 30px;" @click="confirm">确定</el-button>
        </div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="pageSize"
          :page-sizes="[5, 10, 20, 40]"
          layout="total, sizes, prev, pager, next, jumper"
          background
          :total="total"
        ></el-pagination>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        tableData: [],
        imgDataList: [],
        imageRadioId: '',
        changeItem:{},
        value: '',
        orderNumber: '',  //订单号
        total: 0,
        pageSize: 10,
        currentPage:1,
        listLoading: false,
        type: '',
        id: '',
        dialogTableVisible:false,  //添加弹框
        queryId: '',  //上个界面
        findPictureData: {},
        bigTypes: [],  //大类
        smallTypes: [],
        foodNames: [],
        firstName:'',
        secondName:'',
        lastName:'',
        firstNameForFoodName:'',
        secondNameForFoodName:'',
        lastNameForFoodName:'',
        timer: null,
        loading: false,
        createBigTypeFlag: false,
        newBigType: '',
        baseURL: ''
      };
    },
    created () {
      this.baseURL = window.baseURL;
      this.queryBigTypes(); //查询大类
    },
    methods: {
      //添加大类
      createBigType(){
        this.createBigTypeFlag = true;
      },
      cancelCreateBig(){
        this.createBigTypeFlag = false;
        this.newBigType = '';
      },
      changeActiveName(id){
        this.$emit('changeActiveName','second', id)
      },
      reName(type,scope){
        if(scope.row.name === scope.row.newName) return
        console.log('更改了')
        if(type === 'confirm'){
          this.addOrEditBigType('edit',scope);
        }else{
          this.bigTypes[scope.$index].newName = this.bigTypes[scope.$index].name;
        }
      },
      //新增或者重命名
      addOrEditBigType(type,scope){
        let data = {};
        let url = '';
        if(type==="add"){
          data = {
            name: this.newBigType
          }
          url = '/menuName/saveFirstName'
        }else{
          data = {
            name: scope.row.newName,
            id: scope.row.id
          }
          url = '/menuName/updateFirstName'
        }
        console.log(data)
        this.$http.post(url, data, { headers: {
            token: window.sessionStorage.manageToken
          }})
          .then(res => {
            console.log('res====',res)
            console.log('resdata====',res.data)
            //  console.log("输出response.data", res.data);
            if (res.data.code === 'ok') {
              console.log('resdatadata====',res.data.data);
              const data = res.data.data || {}
              if(type==="add"){
                this.createBigTypeFlag = false;
                this.newBigType = '';
                this.$message({
                  type: 'success',
                  message: '新增成功'
                });
              }else{
                this.$message({
                  type: 'success',
                  message: '更改成功'
                });
              }
              this.queryBigTypes(); //查询大类
            } else {
              this.$message.error(res.data.data)
            }
          });
      },
      findOrderInfo(type){
        this.$http.post("/order/findOrderInfo", {id: this.queryId}, { headers: {
            token: window.sessionStorage.manageToken
          }})
          .then(res => {
            console.log('res====',res)
            console.log('resdata====',res.data)
            //  console.log("输出response.data", res.data);
            if (res.data.code === 'ok') {
              console.log('resdatadata====',res.data.data);
              const data = res.data.data || {}
              this.tableData = data.orderInfoSecondList;
              if(type === 'down' &&  data.orderInfo && data.orderInfo.downUrl){
                const url = this.baseURL + '/images'+ data.orderInfo.downUrl;
                window.open(url)
              }
            } else {
              this.$message.error(res.data.data)
            }
          });
      },
      queryBigTypes(){
        this.$http.post(`/menuName/findFirstName`, {}, { headers: {
            token: window.sessionStorage.manageToken
          }})
          .then(res => {
            console.log('res====queryOptions',res)
            console.log('resdata====',res.data)
            if (res.data.code === 'ok') {
              console.log('resdatadata====',res.data.data)
              this.bigTypes = res.data.data;
              this.bigTypes.forEach((item,index)=>{
                this.$set(this.bigTypes[index],'newName',item.name)
               // item.newName = item.name
              })
            } else {
              this.$message.error(res.data.data)
            }
          });
      },
      changeBigType(){
        console.log('changeBigType=======')
        const data = {
          firstId: this.firstName
        }
        this.$http.post("/menuName/findSecondName", data, { headers: {
            token: window.sessionStorage.manageToken
          }})
          .then(res => {
            console.log('res====',res)
            console.log('resdata====',res.data)
            if (res.data.code === 'ok') {
              console.log('resdatadata====',res.data.data)
              this.smallTypes = res.data.data;
              this.secondName = ''
              this.lastName = '';
              this.secondNameForAddSecond = ''
              this.secondNameForFoodName = ''
              this.lastNameForFoodName = ''
            } else {
              this.$message.error(res.data.data)
            }
          });
      },
      changeSmallType(){
        console.log('changeSmallType=======')
        const data = {
          secondId: this.secondName
        }
        this.$http.post("/menuName/findLastName", data, { headers: {
            token: window.sessionStorage.manageToken
          }})
          .then(res => {
            console.log('res====',res)
            console.log('resdata====',res.data)
            if (res.data.code === 'ok') {
              console.log('resdatadata====',res.data.data)
              this.foodNames = res.data.data;
              this.lastName = '';
              this.lastNameForFoodName = ''
              // this.foodNames = res.data.data;
            } else {
              this.$message.error(res.data.data)
            }
          });
      },
      search(){
        this.imageRadioId = ''
        this.currentPage = 1;
        const data = {
          firstId: this.firstName,
          secondId: this.secondName,
          lastId: this.lastName,
          // lastName: this.lastName
        };
        this.findPictureData = data;
        this.findPicture(this.currentPage,this.pageSize);
      },
      handleSizeChange: function (size) {
        this.pageSize = size;
        console.log(this.pageSize);  //每页下拉显示数据
        this.findPicture(this.currentPage,this.pageSize);
      },
      handleCurrentChange: function(currentPage){
        this.currentPage = currentPage;
        console.log(this.currentPage);  //点击第几页
        this.findPicture(this.currentPage,this.pageSize);
      },
      back(){
        this.$router.go(-1);
      },
      findPicture(currentPage,pageSize){
        this.$http.post(`/order/findPicture/${currentPage}/${pageSize}`, this.findPictureData , { headers: {
            token: window.sessionStorage.manageToken,
          }})
          .then(res => {
            console.log('res====',res)
            if (res.data.code === 'ok') {
              console.log('resdatadata====',res.data.data)
              this.imgDataList = res.data.data.list;
              this.total = res.data.data.total;
              this.imageRadioId = ''
              this.dialogTableVisible = true;
            } else {
              this.$message.error(res.data.data)
            }
          });
      },
      confirm(type,item){
        console.log('type===',type)
        item && (this.changeItem = item);
        let data = {
          id:this.changeItem.id
        };
        type !== 'free' && (data.imgId = this.imgDataList[this.imageRadioId].imgId);
        console.log('data',data)
        this.$http.post(`/order/choosePicture`, data , { headers: {
            token: window.sessionStorage.manageToken,
          }})
          .then(res => {
            console.log('res====',res)
            if (res.data.code === 'ok') {
              console.log('resdatadata====',res.data.data)
              const text = type === 'free' ? '免图成功' :'换图成功'
              this.$message.success(text)
              this.dialogTableVisible = false;
              this.findOrderInfo();
            } else {
              this.$message.error(res.data.data)
            }
          });
      },
    },
    watch: {
      dialogTableVisible(newVal){
        if(!newVal){
          this.imageRadioId = '';
          this.currentPage = 1;
          this.firstName = '';
          this.secondName = '';
          this.lastName = '';
        }
      },
    },
    filters: {
      rTime(data){
        if(!data) return "---"
        var dt = new Date(data);
        var y = dt.getFullYear();
        var m = (dt.getMonth()+1).toString().padStart(2,"0");
        var d = dt.getDate().toString().padStart(2,"0");
        var h = dt.getHours().toString().padStart(2,"0");
        var mm = dt.getMinutes().toString().padStart(2,"0");
        var s = dt.getSeconds().toString().padStart(2,"0");
        return `${y}-${m}-${d} ${h}:${mm}:${s}`;
      },
    },
    computed: {
      imageSrcList() {
        let data = [];
        this.imgDataList.forEach(item => {
          data.push(this.baseURL + '/images'+item.imgUrl);
        })
        return data;
      }
    }
  };
</script>
<style lang="less" scoped>
  .container {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  .content {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-top: 10px;
    /*margin-bottom: 20px;*/
    overflow: auto;
    .content-top {
      display: flex;
      margin-top: 10px;
      justify-content: flex-start;
      flex-direction: column;
    }
    .content-bottom {
      flex: 1;
      display: flex;
      flex-direction: column;
      overflow: auto;
    }
  }
  .first-line {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }
  .label {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /*text-align: right;*/
    font-size: 16px;
    color: #000000;
    padding-right: 10px;
  }
  .date-box {
    display: flex;
    align-items: center;
    padding-left: 12px;
  }
  .third-line {
    margin-left: 20px;
    margin-top: 10px;
  }
  .content-box {
    display: flex;
    flex-direction: column;
  }
  .food-content {
    /*flex:1;*/
    display: flex;
    flex-direction: column;
    /*flex-wrap: wrap;*/
    /*justify-content: flex-start;*/
    margin: 10px;
    margin-bottom: 0;
    font-size: 12px;
    overflow: auto;
    .secondName {
      background-color: rgb(111,161,212);
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      height: 38px;
      font-size: 16px;
      position: relative;
      .button-box {
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  .food-box {
    display: flex;
    flex-direction: column;
    width: 230px;
    margin: 0 40px 10px 0;
    position: relative;
    /deep/ .el-checkbox {
      position: absolute;
      top: 5px;
      left: 5px;
    }
    /deep/ .el-checkbox__label {
      display: none;
    }
  }
  .food-image {
    width: 100%;
    height: 200px;
    object-fit: contain;;//转化不同类型
    /*padding: 3px;*/
    /*background-color: #999;*/
    /*box-sizing: border-box;*/
  }
  .image-size {
    display: flex;
    background-color: #999;
    padding: 3px 0 4px;
    align-items: center;
    justify-content: center;
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 3px;
      .image-label {
        display: flex;
        background-color: #ccc;
        width: 40px;
        padding: 3px 10px;
        border-radius: 20px;
        background-color: rgb(225,229,238);
        &:first-child {
          margin-right: 10px;
        }
      }
      .image-value {
        display: flex;
        flex: 1;
        background-color: #fff;
        padding: 3px;
        border-radius: 0 10px 10px 0;
      }
    }
  }
  .image-desc {
    display: flex;
    flex-direction: column;
    .big-type {
      font-size: 14px;
      text-align: left;
      padding: 3px 0 3px 10px;
      border: 3px solid #ccc;
    }
    .image-info {
      font-size: 14px;
      border: 3px solid #ccc;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #ccc;
      .info-item{
        flex: 1;
        /*display: flex;*/
        background-color: #fff;
        margin-right: 5px;
      }
    }
  }
  /deep/ .cell {
    text-align: center;
  }
  /deep/ .el-tooltip.copy  {
    margin-right: 0!important;
    width: auto!important;
  }
  .dialog-image-box {
    display: flex;
    flex-wrap: wrap;
  }
  .dialog-image-item {
    width: 150px;
    height: 150px;
    padding: 5px;
    position: relative;
    /deep/ img {
      width: 100%;
      height: 100%;
      object-fit: contain;;//转化不同类型
    }
    .image-radio {
      position: absolute;
      top: 10px;
      left: 10px;
      /deep/ .el-radio__label {
        display: none;
      }
    }
  }
</style>
